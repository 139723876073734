import {
  UsersIcon, // Zusammenarbeit und Teamdynamik
  HeartIcon , // Vertrauensbildung
  LightBulbIcon, // Kreativität und Innovation
  ShieldCheckIcon, // Sicherheit und Vertrauen im Team
} from '@heroicons/react/24/outline'
import { useRef } from 'react';
import UseIntersectionObserver from './Moving';

const features = [
  {
    name: 'Zusammenarbeit stärken',
    description:
      'Fördern Sie die Zusammenarbeit durch maßgeschneiderte Übungen und Aktivitäten, die darauf abzielen, die Teamdynamik und die Effizienz der Zusammenarbeit zu verbessern.',
    icon: UsersIcon,
  },
  {
    name: 'Vertrauensbildung',
    description:
      'Bauen Sie ein Fundament des Vertrauens innerhalb Ihres Teams auf, das essentiell für eine effektive Kommunikation und starke Arbeitsbeziehungen ist.',
    icon: HeartIcon ,
  },
  {
    name: 'Kreativität und Innovation',
    description:
      'Entfesseln Sie das kreative Potenzial Ihres Teams durch Techniken und Übungen, die das kreative Denken und innovative Lösungsansätze fördern.',
    icon: LightBulbIcon,
  },
  {
    name: 'Konfliktlösung',
    description:
      'Erlernen Sie effektive Strategien zur Konfliktlösung, die es Ihrem Team ermöglichen, Konflikte konstruktiv zu bewältigen und daraus gestärkt hervorzugehen.',
    icon: ShieldCheckIcon,
  },
]

export default function CoachingDetailComp2() {
  const ref = useRef(null); // Create a ref for the element to observe
  const ref2 = useRef(null);
// Apply the custom hook to observe the element
UseIntersectionObserver(ref, { threshold: 0.1 }); // You can adjust the options as needed
UseIntersectionObserver(ref2, { threshold: 0.7 });

  return (
    
    <div className='moveTop bg-red-50 bg-cover bg-no-repeat bg-center' style={{ backgroundImage: "url('Mountainbiker.jpeg')"}} >
    <h2 className="text-center headline text-4xl text1 font-bold tracking-tight text-white sm:text-6xl" style={{paddingBottom:'1%', paddingTop:'4%'}}>Unser Angebot</h2>  
      <div className="mx-auto max-w-7xl py-20 px-6 lg:px-8">
        <div ref={ref} className="hiddeni mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-red-600">FREQUENZ 741</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Team-Coaching
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-200">
          Wir zeigen Ihnen Schlüsselstrategien und Übungen, die speziell darauf ausgerichtet sind, die Zusammenarbeit, Kreativität und Leistungsfähigkeit Ihres Teams zu verbessern.
          </p>
        </div>
        <div ref={ref2} className="hiddeni mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-100">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-300">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="frequency-bars-container">
      <div className="frequency-bars">
        <div className="bars"></div>
        <div className="bars"></div>
        <div className="bars"></div>
        <div className="bars"></div>
        <div className="bars"></div>
      </div></div>
    </div>
  )
}
