import React, { useState, useEffect, useRef } from 'react';
import debounce from 'debounce';
const FrequencyVisualizer = () => {
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
  function resolveBreakpoint( width){
    
    if ( width < 276 ) {
      return '30px';
    } else if ( width >= 276 && width < 376 ) {
      return '30px';
    } else if ( width >= 376 && width < 476 ) {
      return '30px';
    } else if ( width >= 476 && width < 576 ) {
      return '30px';
    } else if ( width >= 576 && width < 768 ) {
      return '20px';
    } else if ( width >= 768 && width < 992 ) {
      return '25px';
    } else if ( width >= 992 && width < 1200 ) {
      return '30px';
    } else if ( width >= 1200 && width < 1440 ) {
      return '30px';
    } else if ( width >= 1440 && width < 1900 ) {
      return '20px';
    } else if ( width >= 1900 && width < 2400 ) {
      return '20px';
    } else if ( width >= 2400 && width < 2800 ) {
      return '0px';
    } else if ( width >= 2800 && width < 3200 ) {
      return '-15px';
    } else if ( width >= 3200 && width < 3600 ) {
      return '-35px';
    } else if ( width >= 3600 && width < 4000 ) {
      return '-55px';
    } else if ( width >= 4000 && width < 4400 ) {
      return '-75px';
    } else if ( width >= 4400 ) {
      return '-95px';
    }
    
  }
  

  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);
    const [index, setIndex] = useState(0);
  
        const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

 
    // Inline styles for the separator
    const separatorStyle = {
      width: '100%',
      position: 'relative',
      top: size,
      zIndex: '2',
     
      backgroundSize: 'cover', // Ensures the logo covers the full width/height
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
    const imgStyle = {
      width: '100%',
      minHeight: '140px',
      maxHeight: '100%',
      objectFit: 'cover', // Stellt sicher, dass das Bild den Container füllt, ohne sein Seitenverhältnis zu verlieren
      objectPosition: 'center'
    };
    return (
      <div className='overflow-hidden frequency-container'>
      <div className='overflow-hidden separatorStyle' style={separatorStyle}>
      <img className='overflow-hidden imgStyle' src={"Logo-Frequenz741-hell2.svg"} alt="Logo" style={imgStyle} />
      </div>
      </div>
    );
  };
  
 

export default FrequencyVisualizer;
