import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const navigate = useNavigate();
    return (
        <div className="absolute inset-x-0 top-0 z-50">
            <nav className="flex items-center justify-between p-3 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a onClick={() => navigate('/')} className="-m-1 p-1">
                        <span className="sr-only">Frequenz 741</span>
                        <img
                            className="h-20 w-auto"
                            src="Logo-Frequenz741-hell.svg"
                            alt=""
                        />
                    </a>
                </div>
                <div className="flex lg:hidden">
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-4">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip-email">E-Mail an info@frequenz741.de schreiben</Tooltip>}
                    >
                        <a onClick={() => window.location.href='mailto:info@frequenz741.de'} className="px-2 text-sm font-semibold leading-6 text-gray-700">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip-phone"><Badge>Telefonnummer +49 172 3570148 anrufen</Badge></Tooltip>}
                    >
                        <a onClick={() => window.location.href='tel:+491723570148'} className="px-2 text-sm font-semibold leading-6 text-gray-700">
                            <FontAwesomeIcon icon={faPhone} />
                        </a>
                    </OverlayTrigger>
                </div>
            </nav>
        </div>
    );
};

export default Header;
