import { useEffect, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import axios from 'axios';
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router-dom';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Contact({ message }) {
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();
  

  const [agreed, setAgreed] = useState(false)
  const initial={
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phoneNumber: '',
    message: '',
    address: '',
  };
  const [formData, setFormData] = useState(initial);


 

  

 
  // Setzt die Autorinformationen, wenn das Popup geöffnet wird und der Autorname sich ändert



// Funktion zum Aktualisieren des Formularzustands
const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData({
    ...formData,
    [name]: value,
  });
};
 // Funktion zum Überprüfen der Formulareingaben
 const validateForm = () => {
  // Einfache Validierung als Beispiel
  if (!formData.firstName || !formData.email || !agreed) {
    alert("Bitte füllen Sie alle erforderlichen Felder aus und bestätigen Sie, dass Sie kein Roboter sind.");
    return false;
  }
  return true;
};

// Funktion zum Behandeln des Formularabsendens
const handleSubmit = (event) => {
  
  event.preventDefault();
  console.log("Formulardaten:", formData);
  if (validateForm()) {
    // Directly use the formData state variable
    //axios.post(process.env.REACT_APP_PUBLIC_BACKEND+'/api/contact/frequency', formData)
    axios.post('https://backend.frequenz741.de/api/contact', formData)
    .then(response => {
      console.log('Success:', response.data);
      setShowConfetti(true); // Konfetti-Regen anzeigen
      setFormData(initial);
      setTimeout(() =>{setShowConfetti(false); alert("Formular erfolgreich gesendet!"); }, 5000);
      
      // Optional: Zustand nach einigen Sekunden zurücksetzen
    
    })
    .catch(error => {
      console.error('Error:', error);
      alert("Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.");
    });
  } else {
    alert("Bitte füllen Sie alle erforderlichen Felder aus.");
  }
};

  return (
    <div className='moveTop'>
    {showConfetti && <Confetti />}
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8 " >

      <div
  className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
  aria-hidden="true"
>
  <div
    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#b50505] to-[#b50505] opacity-30"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>
<div
  className="absolute top-[0rem] left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[0rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
  aria-hidden="true"
>
  <div
    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#b50505] to-[#b50505] opacity-30"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>

      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
Kontaktieren Sie uns!</h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
        Haben Sie Interesse an unseren Führungskräfte- oder Team-Coachings? Möchten Sie mehr über unsere Dienstleistungen erfahren oder einen Coaching-Termin vereinbaren? Zögern Sie nicht, sich bei uns zu melden: </p>
        <p className="mt-2 text-lg leading-8 text-gray-500" style={{fontWeight:'lighter'}}>Alternativ direkt per Telefon <a href="tel:+491723570148" className="text-blue-400 hover:underline">+49 172 3570148</a> oder E-Mail <a href="mailto:info@frequenz741.de" className="text-blue-400 hover:underline">info@frequenz741.de</a>
        </p>
      </div>
      <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-xl sm:mt-20">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div>
            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Vorname
            </label>
            <div className="mt-2.5">
              <input
                required
                placeholder="Hier Vorname eintragen"
                type="text"
                name="firstName"
                id="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
              Nachname
            </label>
            <div className="mt-2.5">
              <input
               placeholder="Hier Nachname eintragen"
                required
                type="text"
                name="lastName"
                id="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                autoComplete="family-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
              Unternehmen
            </label>
            <div className="mt-2.5">
              <input
               placeholder="Hier Unternehmen eintragen"
                type="text"
                name="company"
                id="company"
                value={formData.company}
                onChange={handleInputChange}
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
              Email
            </label>
            <div className="mt-2.5">
              <input
                required
                placeholder="Hier Email eintragen"
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
             Telefonnummer
            </label>
            <div className="relative mt-2.5">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="country" className="sr-only">
                  Land
                </label>
                <select
                  id="country"
                  name="country"
                  className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 sm:text-sm"
                >
                  <option>EU</option>
                  <option>US</option>
                </select>
                <ChevronDownIcon
                  className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
               placeholder="Hier Telefonummer eintragen"
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                autoComplete="tel"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
              Nachricht
            </label>
            <div className="mt-2.5">
              <textarea
                placeholder="Hier Nachricht eintragen"
                required
                value={formData.message}
                onChange={handleInputChange}
                name="message"
                id="message"
                rows={5}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-800 sm:text-sm sm:leading-6"
                defaultValue={''}
              />
            </div>
          </div>
          <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
            <div className="flex h-6 items-center">
              <Switch
                checked={agreed}
                onChange={setAgreed}
                className={classNames(
                  agreed ? 'bg-red-800' : 'bg-gray-200',
                  'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-800'
                )}
              >
                <span className="sr-only">Zustimmung Datenschutzrichtlinien</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    agreed ? 'translate-x-3.5' : 'translate-x-0',
                    'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>
            <Switch.Label className="text-sm leading-6 text-gray-600">
              Bei Auswahl stimmen Sie unseren {' '}
              <a onClick={(e)=>navigate('/datenschutz')} className="font-semibold text-red-800">
                Datenschutzrichtlinien&nbsp;
              </a>zu.
            </Switch.Label>
          </Switch.Group>
        </div>
        <div className="mt-10">
          <button
        
            type="submit"
            className="block w-full rounded-md bg-red-800 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-800"
          >
            Absenden
          </button>
        </div>
      </form>
    </div>
    </div>
  )
}