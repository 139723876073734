import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import React from 'react';





import { Helmet } from 'react-helmet';
import Landing from "./components/Landing";
import FrequencyVisualizer from "./components/FrequencyVisualizer";
import Stefan from "./components/Stefan";
import Coaching from "./components/Coaching";
import CoachingDetailComp from "./components/CoachingDetailComp";
import CoachingDetailComp2 from "./components/CoachingDetailComp2";
import Enrico from "./components/Enrico";
import Comment from "./components/Comment";
import Locations from "./components/Locations";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Cookies from "./components/Cookies";
import Datenschutz from "./components/Datenschutz";
import Impressum from "./components/Impressum";
import Header from "./components/Header";




function App() {
  
  return (
    <>
    <Helmet>
  <script type="application/ld+json">
    {`
      {
        "@context": "http://schema.org",
        "@type": "Article",
        "headline": "Frequenz 741",
        "description": "Frequenz 741 bietet professionelles Führungskräfte Coaching und Team Coaching, geleitet von Stefan Wiesner und Enrico Pohl. Entfalten Sie das Potenzial Ihrer Führungskräfte und stärken Sie Ihre Teamdynamik für nachhaltigen Erfolg.",
        "author": {
          "@type": "Person",
          "name": "Stefan Wiesner, Enrico Pohl"
        },
        "datePublished": "2024-03-18"
      }
    `}
  </script>
</Helmet>
    <BrowserRouter>
            <Routes>
              <Route path="/" element={ 
              <>
            
              <Landing/>  
          
              </>
              }> </Route>

<Route path="/impressum" element={ 
              <>
              <Header/>
              <Impressum/>
              <Footer/>
              
              </> 
              }> </Route>
                 
               <Route path="/datenschutz" element={ 
              <>
              <Header/>
              <Datenschutz/>
              <Footer/>
              
              </> 
              }> </Route>
             </Routes>
             <Cookies/>
        </BrowserRouter>
    </>

  );
}

export default App;
