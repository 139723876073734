import { useRef } from "react";
import UseIntersectionObserver from "./Moving";

export default function Comment() {
    const ref = useRef(null); // Create a ref for the element to observe
    const ref2 = useRef(null);
  // Apply the custom hook to observe the element
  UseIntersectionObserver(ref, { threshold: 0.1 }); // You can adjust the options as needed
  UseIntersectionObserver(ref2, { threshold: 0.7 });
    return (
        <>
       
      <section className="relative isolate overflow-hidden z-0  px-6 py-10 sm:py-10 lg:px-8 commentSection">

      <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-40"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#bf0000ff] to-[#ff2828ff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
<div
  className="absolute -top-10 left-1/2  -translate-x-1/2 transform-gpu blur-3xl sm:top-[-5rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
  aria-hidden="true"
>
  <div
    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#b50505] to-[#b50505] opacity-20"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>
<div
  className="absolute -top-10 left-1/2  -translate-x-1/4 transform-gpu blur-3xl sm:top-[-5rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
  aria-hidden="true"
>
  <div
    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#b50505] to-[#b50505] opacity-20"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>
        <div className="mx-auto max-w-2xl py-24 lg:max-w-7xl">
          
          <figure className="mt-5">
            <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 textsize sm:text-2xl sm:leading-9">
              <p ref={ref} className="hiddeni">
                “<a style={{fontWeight:'bold'}} >Frequenz 741 </a> steht nicht nur als Name für unser Unternehmen, sondern verkörpert die Essenz unserer Philosophie in der Welt des Coachings. In der Musiktherapie gilt die Frequenz 741 Hz als kraftvoll reinigend, unterstützt bei der Lösung von Problemen und öffnet den Geist für Lösungen. Genau diese Transformation und Klarheit bringen wir in die Führungskräfte- und Team-Entwicklung ein. </p>
                <p ref={ref2} className="hiddeni">Bei Frequenz 741 begleiten wir Sie auf einer Reise, auf der Sie nicht nur Ihre eigenen Herausforderungen meistern, sondern auch eine harmonischere, produktivere und innovativere Arbeitsumgebung schaffen. Unsere Methoden sind darauf abgestimmt, das volle Potenzial Ihrer Führungskräfte und Teams freizusetzen, indem wir auf die 'reinigenden' Kräfte von Verständnis, Entwicklung und zielgerichteter Aktion setzen. Entdecken Sie mit Frequenz 741, wie Sie und Ihr Team in Einklang und auf eine höhere Ebene des Erfolgs gelangen können."
              </p>
            </blockquote>
            <figcaption className="mt-10">
           
              <div className="mt-4 flex items-center justify-center textsize2 space-x-3 text-base">
                <div className="font-semibold text-gray-900">Stefan Wiesner & Enrico Pohl</div>
               
               
              </div>
            </figcaption>
          </figure>
        </div>
      </section>
      </>
    )
  }