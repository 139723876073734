import {
  SpeakerWaveIcon,
  UserGroupIcon,
  ChartBarIcon,
  HeartIcon,
} from '@heroicons/react/24/outline'
import { useRef } from 'react';
import UseIntersectionObserver from './Moving';

const features = [
  {
    name: 'Mitarbeiter führen',
    description:
      'Kommunikation ist die Basis für ein erfolgreiches Miteinander. Bringen Sie Ihre Ziele und Mitarbeiter in Einklang als Grundlage einer wertschätzenden und leistungsorientierten Mitarbeiterführung.',
    icon: UserGroupIcon,
  },
  {
    name: 'Mitarbeiter motivieren',
    description:
      'Wir zeigen Ihnen Motivationsstrategien auf, mit welchen Sie den Teamgeist und das WIR-Gefühl in Ihrem Unternehmen stärken. Damit erreichen Sie eine höhere Attraktivität als Arbeitgeber und binden Mitarbeiter an Ihr Unternehmen.',
    icon: HeartIcon,
  },
  {
    name: 'Kommunizieren und delegieren',
    description:
      'Warum kommunizieren Menschen wie sie kommunizieren? Wir machen eingetretene Pfade der Kommunikation transparent und zeigen Ihnen Instrumente für einen bewussteren und nachhaltigeren Austausch mit Ihren Mitarbeitern auf.',
    icon: SpeakerWaveIcon,
  },
  {
    name: 'Leadership – mehr als nur Führung',
    description:
      'Reflektieren Sie mit uns Ihren Führungsstil und arbeiten Sie mit zielgerichteten Energien an einem authentischen, transparenten und effektiven Führungsverhalten.  ',
    icon: ChartBarIcon,
  },
]

export default function CoachingDetailComp() {
  const ref = useRef(null); // Create a ref for the element to observe
  const ref2 = useRef(null);
// Apply the custom hook to observe the element
UseIntersectionObserver(ref, { threshold: 0.1 }); // You can adjust the options as needed
UseIntersectionObserver(ref2, { threshold: 0.7 });

  return (
    <div className='moveTop bg-red-50 bg-cover bg-no-repeat bg-center' style={{ backgroundImage: "url('Fahrradhelm im Nebel.jpeg')"}} >
    <div className=" py-20 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div ref={ref} className="hiddeni mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-red-600">FREQUENZ 741</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Führungskräfte-Coaching
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
          Wir vermitteln Ihnen moderne Instrumente, Methoden und Techniken für eine erfolgreiche Führungsaufgabe.
          </p>
        </div>
        <div ref={ref2} className="hiddeni mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-100">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-300">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    <div className="frequency-bars-container">
      <div className="frequency-bars">
        <div className="bars"></div>
        <div className="bars"></div>
        <div className="bars"></div>
        <div className="bars"></div>
        <div className="bars"></div>
      </div>
    </div>
    </div>
  )
}
