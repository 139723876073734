import { useRef } from "react";
import UseIntersectionObserver from "./Moving";

export default function Coachingoffer() {
  // Erweiterte Links und Stats mit neuen Informationen
  const ref = useRef(null); // Create a ref for the element to observe
  const ref2 = useRef(null);
  const ref3 = useRef(null); 
  const ref4 = useRef(null); 
  const ref5 = useRef(null); 
  const ref6 = useRef(null); 
  const ref7 = useRef(null); 
  const ref8 = useRef(null); 
  const ref9 = useRef(null); 
  const ref10 = useRef(null); 

// Apply the custom hook to observe the element
UseIntersectionObserver(ref, { threshold: 0.1 }); // You 
UseIntersectionObserver(ref2, { threshold: 0.2 }); // You 
UseIntersectionObserver(ref3, { threshold: 0.3 }); // You 
UseIntersectionObserver(ref4, { threshold: 0.4 }); // You 
UseIntersectionObserver(ref5, { threshold: 0.5 }); // You 
UseIntersectionObserver(ref6, { threshold: 0.6 }); // You 
UseIntersectionObserver(ref7, { threshold: 0.7 }); // You 
UseIntersectionObserver(ref8, { threshold: 0.8 }); // You 
UseIntersectionObserver(ref9, { threshold: 0.9 }); // You 
UseIntersectionObserver(ref10, { threshold: 1 }); // You 
  

const links = [
    
  ];

  const stats = [
    { name: 'Frequenz 741', value: 'Harmonisierung von Geist und Arbeitsumfeld.' },
    { name: 'Orte mit Wohlfühlatmosphäre', value: 'Einzigartige Orte für unvergessliche Workshops.' },
    { name: 'Outdoor-Aktivitäten', value: 'Förderung von Kreativität und Teamgeist im Freien.' },
    { name: 'Umweltbewusstsein', value: 'Engagement für Nachhaltigkeit in allen Bereichen.' },
    { name: 'Persönliches Wachstum', value: 'Gezielte Förderung der individuellen Entwicklung.' },
    { name: 'Gemeinschaft und Zusammenhalt', value: 'Aufbau einer starken, unterstützenden Gemeinschaft.' },
    { name: 'Kreativitätsförderung', value: 'Anregung von Innovation durch inspirierende Umgebungen.' },
    { name: 'Positive Erfahrungen', value: 'Erlebnisse, die persönlich und beruflich bereichern.' },
  ];
  return (
    <div className="relative moveTop isolate overflow-hidden bg-gray-900 " style={{paddingTop:'8%'}}>
          <img
        src="Landschaft Erzgebirge mit Dorf.jpeg"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#b50505] to-[#b50505] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#b50505] to-[#b50505] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div ref={ref} className="hiddeni mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h2  className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Unser Konzept</h2>
          <p ref={ref2} className="hiddeni mt-10 text-lg leading-8 text-gray-200">
          Fernab vom Alltag tauchen Sie mit uns in ein inspirierendes Mensch-Natur-Erlebnis ein, geprägt durch die transformative <strong>Frequenz 741</strong>. Unsere Team- und Führungskräfte-Coachings sind nicht nur Veranstaltungen - sie sind Reisen in die Natur, konzipiert, um zu energetisieren, die Kommunikation zu stärken und  Teams zu vereinen bzw. die Rolle als Führungskraft erfolgreicher auszuüben. Unsere abwechslungsreichen Outdoor-Aktivitäten regen nicht nur die Kreativität an, sondern bringen jede Menge Spaß und fördern darüber hinaus eine bewusstere Wahrnehmung der Natur. Unser Ziel ist Ihr persönliches Wachstum, Harmonie und gemeinsamer Erfolg.          </p>
        </div>
        {/* Link und Stat Abschnitte wie zuvor, jetzt aber mit den erweiterten Daten */}
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
            {links.map((link) => (
              <a key={link.name} href={link.href} className="hover:underline">
                {link.name} <span aria-hidden="true">&rarr;</span>
              </a>
            ))}
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div  key={stat.name} className="flex flex-col-reverse">
                <dt className="text-base leading-7 text-gray-300">{stat.value}</dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.name}</dd>
              </div>
            ))}
          </dl>
        </div>
        
      </div>
      <div className="frequency-bars-container">
      <div className="frequency-bars">
        <div className="bars"></div>
        <div className="bars"></div>
        <div className="bars"></div>
        <div className="bars"></div>
        <div className="bars"></div>
      </div></div>
    </div>
  )
}
