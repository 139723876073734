import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkedAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer  bg-black text-white p-6">
      <div className=" mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 items-center text-center">
          <div className="flex flex-col items-center">
            <a onClick={(e)=>navigate("/impressum")} className="hover:text-gray-400 mb-2">Impressum</a>
            <a onClick={(e)=>navigate("/datenschutz")} className="hover:text-gray-400">Datenschutz</a>
          </div>
          <div>
            <p>© 2024 Frequenz741. Alle Rechte vorbehalten.</p>
          </div>
          <div className="flex flex-col items-center">
          <a onClick={() => window.location.href='mailto:info@frequenz741.de'} className="px-2 text-sm font-semibold leading-6 text-gray-300">
            <FontAwesomeIcon icon={faEnvelope} />
            </a>
            <a onClick={() => window.location.href='tel:+493591351081'} className="px-2 text-sm font-semibold leading-6 text-gray-300">
            <FontAwesomeIcon icon={faPhone} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
