import { useNavigate } from "react-router-dom";


const Impressum = () => {
    const navigate = useNavigate();
    return (
      <div className="max-w-4xl mx-auto p-4" style={{marginTop:'3%'}}>
        <h1 className="text-xl font-bold mb-4">Impressum</h1>
        <p className="mb-2"><strong>Name:</strong> BIKEpoint Wiesner | Inhaber: Stefan Wiesner</p>
        <p className="mb-2"><strong>Adresse:</strong> Muskauer Str. 54, 02625 Bautzen</p>
        <p className="mb-2"><strong>E-Mail:</strong> <a href="mailto:info@frequenz741.de" className="text-blue-500 hover:underline">info@frequenz741.de</a></p>
        <p className="mb-2"><strong>Telefon:</strong> <a href="tel:+491723570148" className="text-blue-500 hover:underline">+49 172 3570148</a></p>
        <p className="mb-2"><strong>USt-IdNr.:</strong> DE 222670372</p>    
        <p className="mb-2"><strong>Hinweis zur EU-Streitschlichtung:</strong> Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/odr" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/odr</a>.</p>
        <p className="mb-2"><strong>Streitbeilegungsverfahren:</strong> Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.</p>
        
  
        <h2 className="text-lg font-bold mb-2">Haftung für Inhalte</h2>
        <p className="mb-2">Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
  
        <h2 className="text-lg font-bold mb-2">Haftung für Links</h2>
        <p className="mb-2">Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
  
        <h2 className="text-lg font-bold mb-2">Urheberrecht</h2>
        <p className="mb-2">Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
        
      
   
      
      
      </div>
    );
  };
  
  export default Impressum;