import {BriefcaseIcon, HeartIcon, UserGroupIcon} from '@heroicons/react/20/solid'
import { useRef } from 'react';
import UseIntersectionObserver from './Moving';

const features = [
  {
    name: 'Kommunikationsexperte',
    description: 'Mit Erfahrungen in der Automobilbranche und im öffentlich-rechtlichen Hörfunk sowie einem Wirtschaftsstudium im Rücken, fokussiere ich mich seit 2011 auf die Bereiche Moderation, Präsentation und Coaching. Als Systemischer Coach unterstütze ich nachhaltige Veränderungen und Entwicklung.',    icon: BriefcaseIcon,
  },
  {
    name: 'Liebe zur Natur',
    description: 'Natur bedeutet für mich Entschleunigung. Zu jeder Jahreszeit schöpfe ich Kraft und Inspiration aus dem Wald.',
    icon: HeartIcon,
  },
  {
    name: 'Privates Engagement',
    description: 'Wenn ich gerade nicht arbeite, dann verbringe ich Zeit mit meiner Familie und bin in der Natur unterwegs. Laufsport und Motorradtouren gehören ebenso zu meinen Leidenschaften.',
    icon: UserGroupIcon,
  },
]

export default function Enrico() {
  const ref = useRef(null); // Create a ref for the element to observe
  const ref2 = useRef(null);
// Apply the custom hook to observe the element
UseIntersectionObserver(ref, { threshold: 0.1 }); // You can adjust the options as needed
UseIntersectionObserver(ref2, { threshold: 0.7 });
  return (
   <div className='moveTop '>
    <div className=" relative  mx-auto max-w-7xl py-6 sm:px-6 sm:py-20 lg:px-8">
      
      <div className="relative isolate overflow-hidden bg-black px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
        <svg
          viewBox="0 0 1024 1024"
          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          aria-hidden="true"
        >
          <circle cx={512} cy={512} r={512} fill="red" fillOpacity="0.5" />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
              <stop stopColor="#7775D6" />
              <stop offset={1} stopColor="#b50505" />
            </radialGradient>
          </defs>
        </svg>
          <div className="text-red-600 py-20 sm:py-20">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2" style={{alignItems:'center'}}>
                <div className="lg:pr-8 lg:pt-4">
                  <div ref={ref} className="hiddeni lg:max-w-lg">
                    <h2 className="text-base font-semibold leading-7 text-red-600">Enrico Pohl</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Moderator, Coach, Naturfreund</p>
                    <p className="mt-6 text-lg leading-8  text-gray-100">
                      Mit einem reichen Hintergrund in Kommunikation und Coaching, engagiere ich mich für die persönliche und berufliche Entwicklung von Einzelpersonen und Unternehmen. Privat finde ich Ausgleich und Inspiration in der Natur und bei sportlichen Aktivitäten. Ich bin kommunikativ, emphatisch und  gesangsfreudig.
                    </p>
                    <dl className="mt-10 max-w-xl space-y-8 text-base leading-7  text-gray-300 lg:max-w-none">
                      {features.map((feature) => (
                        <div key={feature.name} className="relative pl-9">
                          <dt className="inline font-semibold text-gray-100">
                            <feature.icon className="absolute left-1 top-1 h-5 w-5 text-red-600" aria-hidden="true" />
                            {feature.name}
                          </dt>{' '}
                          <dd className="inline">{feature.description}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
                {/* Placeholder for an image of Enrico Pohl */}
                <img ref={ref2}
                  src="Enrico_Profilbild.jpeg"
                  alt="Enrico Pohl"
                  className="hiddeni w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                  style={{height: '600px', width:'100%', objectFit: 'cover'}}
                />
              </div>
            </div>
          </div>   
        </div>
      </div>
      </div>
  )
}
