import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Cookies = () => {
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(false);
  const [consentDetails, setConsentDetails] = useState({
    necessary: true, // Notwendige Cookies sind immer aktiviert
    preferences: false,
    statistics: false,
    marketing: false,
  });

  useEffect(() => {
    const storedConsent = JSON.parse(localStorage.getItem('cookieConsent'));
    if (storedConsent) {
      setConsentDetails(storedConsent);
    } else {
      setShowBanner(true);
    }
  }, []);

  const handleConsentChange = (category, value) => {
    const updatedConsent = { ...consentDetails, [category]: value };
    setConsentDetails(updatedConsent);
  };

  const saveConsent = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(consentDetails));
    setShowBanner(false);
  };

  return (
    <div className={`fixed static bottom-0 w-screen  left-0 right-0 ${showBanner ? 'flex' : 'hidden'} flex-wrap opacity-80 bg-black text-white p-4 justify-between items-center z-10`} >
      <div className=" w-1/3 p-2">
        <p>Wir verwenden Cookies 🍪, um Ihre Erfahrung auf unserer Website zu verbessern. Lesen Sie unsere <a onClick={(e)=>navigate("/datenschutz")} className="underline">Datenschutzrichtlinie</a> für weitere Informationen.</p>
      </div>
      <div className=" w-1/3 p-2 flex flex-col">
        <div className="flex items-center justify-center md:justify-start">
          <input type="checkbox" checked className="mr-2" readOnly />
          Notwendige Cookies
        </div>
        {['preferences', 'statistics', 'marketing'].map((category) => (
          <label key={category} className="flex items-center justify-center md:justify-start">
            <input 
              type="checkbox"
              checked={consentDetails[category]}
              onChange={(e) => handleConsentChange(category, e.target.checked)}
              className="mr-2 cookie"
            />
            {category.charAt(0).toUpperCase() + category.slice(1)}-Cookies
          </label>
        ))}
      </div>
      <div className=" p-2 flex flex-row md:flex-col space-x-2 md:space-x-0 md:space-y-2 justify-center md:justify-start">
        <button className="cookie bg-red-700 hover:bg-red-400 text-white font-bold py-2 px-4 rounded" onClick={saveConsent}>Auswahl bestätigen</button>
        <button className="cookie bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded" onClick={() => setShowBanner(false)}>Schließen</button>
      </div>
    </div>
  );
};

export default Cookies;
